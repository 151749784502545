import { Box, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { MOBILE_NAVS } from "../../constants";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { NavLink } from "react-router-dom";

function MobileHamBugerNav() {
  const [show, setShow] = useState(false);
  const [user] = useAuthState(auth);

  return (
    <>
      <GiHamburgerMenu
        onClick={() => setShow((prev) => !prev)}
        fontSize={"2em"}
      />
      {show && (
        <Box
          data-aos="fade-left"
          position="fixed"
          bottom={"0"}
          left="0"
          right="0"
          top="0"
          w="100vw"
          bg="#111827"
          zIndex={1022}
        >
          <Flex
            flexDir={"column"}
            justifyContent="center"
            alignItems={"center"}
            w="100%"
            p="20px"
          >
            <FaTimes
              style={{ alignSelf: "flex-end" }}
              fontSize="1.5em"
              cursor={"pointer"}
              onClick={() => setShow(false)}
            />
            <Flex
              flexDir={"column"}
              alignItems="center"
              justifyContent="center"
              gap="20px"
            >
              {MOBILE_NAVS.map((data, id) => {
                return (data.title === "Login" && user?.email) ||
                  (data.title === "Register" && user?.email) ? (
                  ""
                ) : (
                  <NavLink
                    key={id}
                    to={data.link}
                    onClick={() => setShow(false)}
                  >
                    <Text fontSize={"1.2em"}>{data.title}</Text>
                  </NavLink>
                );
              })}
            </Flex>
          </Flex>
        </Box>
      )}{" "}
    </>
  );
}

export default MobileHamBugerNav;
