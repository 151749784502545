import { useState } from "react";

export const useDispatchActions = () => {
  const [state, setState] = useState({
    imgWidth: 500,
    imgHeight: 500,
    texts: [],
    activeFontFamily: null,
    fontColor: "#000",
    fontSize: "20px",
    fontList: [],
    remain: 0,
    downloadFormat: "IMG",
  });

  const dispatchUserEvent = (actionType, payload) => {
    switch (actionType) {
      case "BACKGROUND_COLOR":
        setState({
          ...state,
          ...payload,
        });
        return;
      case "PAYLOAD":
        setState({
          ...state,
          ...payload,
        });
        return;
      case "ADD_TEXT":
        setState({
          ...state,
          texts: [...state.texts, payload],
        });
        return;
      case "SINGLE_TEXT":
        setState({
          ...state,
          texts: [payload],
        });
        return;
      case "CLEAR_TEXT":
        setState({
          ...state,
          texts: [],
        });
        return;

      case "CSV_JSON":
        setState({
          ...state,
          csvJson: payload,
        });
        return;

      case "CHANGE_FONT_FAMILY":
        setState({
          ...state,
          activeFontFamily: payload,
        });
        return;

      case "ADD_FONT_FAMILY_TO_LIST":
        setState({
          ...state,
          fontList: [...state.fontList, payload],
        });
        return;

      case "CHANGE_FONT_SIZE":
        setState({
          ...state,
          fontSize: payload,
        });
        return;

      case "CHANGE_FONT_COLOR":
        setState({
          ...state,
          fontColor: payload,
        });
        return;
      case "ADD_COUNT":
        setState((state) => ({
          ...state,
          remain: state.remain + 1,
        }));
        return;
      case "END_COUNT":
        setState({
          ...state,
          remain: 0,
        });
        return;

      case "OTHERS":
        setState({
          ...state,
          ...payload,
        });
        return;

      default:
        return state;
    }
  };
  return {
    state,
    dispatch: async (action, payload) => dispatchUserEvent(action, payload),
  };
};
