import {
  Avatar,
  Box,
  Button,
  Divider,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  BACKEND_BASE_URL,
  NAVS,
  NAV_CALL_TO_ACTION_STYLE,
  NAV_CONTAINER_STYLE,
  NAV_LIST_CONTAINER_STYLE,
} from "../../constants";

import { auth, logout } from "../../firebase";
import { IoIosArrowDown } from "react-icons/io";
import { useAuthState } from "react-firebase-hooks/auth";
import { useState } from "react";
import axios from "axios";
import { successNotifier } from "../notification/NotificationHandler";
import { useCheckUserSubscriptionStatus } from "../../hooks/useCheckUserSubscriptionStatus";
import MobileHamBugerNav from "./MobileHamburgerMenu";
import dayjs from "dayjs";

export const Navigation = ({ ...props }) => {
  const navigate = useNavigate();
  const [isMobileScreen] = useMediaQuery("(max-width: 600px)");
  const [user] = useAuthState(auth);
  const [reload, setReload] = useState({});

  const {
    subscriptionStatus,
    subscriptionID,
    hasCancelledSubscription,
    subscriptionEndDate,
    navNotification,
  } = useCheckUserSubscriptionStatus({ email: user?.email, reload });

  const cancelSub = (id) => {
    axios
      .post(`${BACKEND_BASE_URL}/cancel`, { id })
      .then((res) => {
        setReload({});
        successNotifier(res?.data?.response?.message);
      })
      .catch(() => {});
  };
  return (
    <>
      {navNotification && (
        <Flex justifyContent={"center"} mt="30px" bg="#1b4caa" p="10px">
          <Text textAlign="center" fontWeight="bold" color="#fff">
            {navNotification}
          </Text>
        </Flex>
      )}
      <Stack
        {...NAV_CONTAINER_STYLE}
        {...props}
        direction={["row", "row"]}
        backgroundColor="#111827"
      >
        <Stack direction={"row"} alignItems="center">
          <Box>
            <Text
              whiteSpace={"nowrap"}
              fontSize={["1.3em", "1.4em"]}
              fontWeight="bold"
              color="#fff"
            >
              <NavLink to="/">In-cert.</NavLink>
            </Text>
          </Box>
        </Stack>

        <Flex alignItems="center" gap={["5px", "20px"]}>
          <Stack
            {...NAV_LIST_CONTAINER_STYLE}
            fontSize=".8em"
            display={["none", "block"]}
          >
            {NAVS.map((data, id) => {
              return (data.title === "Login" && user?.email) ||
                (data.title === "Register" && user?.email) ? (
                ""
              ) : (
                <NavLink key={id} to={data.link}>
                  {data.title}
                </NavLink>
              );
            })}
            <Button
              {...NAV_CALL_TO_ACTION_STYLE}
              p={["10px", "15px"]}
              onClick={() => navigate("/editor")}
            >
              Try for free
            </Button>
          </Stack>

          {isMobileScreen && <MobileHamBugerNav />}

          {user && (
            <>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<IoIosArrowDown />}
                  bg="transparent"
                  border="none"
                  _active={{ background: "transparent", boxShadow: "none" }}
                  _hover={{ background: "transparent" }}
                  _focus={{ background: "transparent", boxShadow: "none" }}
                  transition="all 0.2s"
                  borderRadius="md"
                >
                  {" "}
                  <Avatar
                    size={"sm"}
                    name={user?.displayName}
                    src={user?.photoURL}
                    alt="user"
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    _focus={{ background: "transparent" }}
                    onClick={logout}
                  >
                    Logout
                  </MenuItem>
                  {subscriptionStatus === "active" &&
                    hasCancelledSubscription === false && (
                      <>
                        <Divider />
                        <MenuItem
                          _focus={{ background: "transparent" }}
                          onClick={() => cancelSub(subscriptionID)}
                        >
                          Cancel subscription
                        </MenuItem>
                      </>
                    )}

                  {subscriptionStatus === "active" &&
                    hasCancelledSubscription === true && (
                      <>
                        <Text
                          lineHeight="1.7"
                          maxW="300px"
                          bg="#f5fffa"
                          p="10px"
                          fontSize=".8em"
                        >
                          You have Unsubscribed to Premium.
                          <br /> please note that your Subscription would end on{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {dayjs(subscriptionEndDate).format(
                              "D, MMMM, YYYY "
                            )}
                          </span>
                        </Text>
                      </>
                    )}
                </MenuList>
              </Menu>
            </>
          )}
        </Flex>
      </Stack>
    </>
  );
};
