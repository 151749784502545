import "./App.css";
import AppContext from "./appContext/AppContext";
import { useDispatchActions } from "./appContext/useDispatchActions";
import { RoutesComponent } from "./components/RoutesComponent";

function App() {
  const { state, dispatch } = useDispatchActions();

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <RoutesComponent />
    </AppContext.Provider>
  );
}

export default App;
