import { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { BACKEND_BASE_URL } from "../constants";

const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export const useCheckUserSubscriptionStatus = (props) => {
  const [navNotification, setNavNotification] = useState(null);
  const [subStatus, setSubStatus] = useState(
    // "active"
    null
  );

  const [hasCancelledSubscription, setHasCancelledSubscription] =
    useState(false);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState("");
  const [subID, setSubID] = useState(null);

  const email = props?.email;
  const reload = props?.reload;

  const runFreeForXDays = () => {
    // automatically, user fall under the free x days if they havent signed up
    // add free x days, giving users free days to try the software
    const FREE_X_DAY_TO_STORE = dayjs().add(5, "day").format();

    const freeCountDownDate = localStorage.getItem("in-cert-free-x-day");

    // if free x days hasnt been saved then save it, for user to try software
    if (!freeCountDownDate) {
      localStorage.setItem("in-cert-free-x-day", FREE_X_DAY_TO_STORE);
      setSubStatus("active");
      setNavNotification(
        "Test In-cert today with 5-days free trial, your countdown has started!!! 🎁🎁"
      );
      return;
    }

    const futureDate = new Date(dayjs(freeCountDownDate).format());
    const today = new Date();
    const freeHasEnded = today >= futureDate ? true : false;

    if (!freeHasEnded) {
      setSubStatus("active");
      setNavNotification(
        `You have ${dayjs().to(futureDate, true)} free trial remaining. ⏰⏰`
      );
      return;
    }

    // free has ended
    setNavNotification(
      "Your free trial has ended, Subscribe now!! to continue."
    );
  };

  useEffect(() => {
    if (!email) {
      runFreeForXDays();

      return;
    }
    axios
      .post(`${BACKEND_BASE_URL}/api`, { email })
      .then((res) => {
        const userHasAnActiveSubscription =
          res?.data?.response?.data?.length > 0 &&
          res?.data?.response?.data?.[0]?.status === "active";

        const todayDate = new Date();
        const FutureSubscriptionEndDate = new Date(
          dayjs(new Date(res?.data?.response?.data?.[0]?.created_at))
            .add(1, "month")
            .format()
        );

        // check if subscription date has ended
        const _SUBSCRIPTION_DATE_HAS_NOT_ENDED =
          FutureSubscriptionEndDate >= todayDate;

        //  user may have cancelled their subscription, but
        // their their subscription has really expired because of the subscription date hasnt expired
        if (userHasAnActiveSubscription !== "active") {
          setHasCancelledSubscription(true);
          setSubscriptionEndDate(FutureSubscriptionEndDate);
        }

        if (userHasAnActiveSubscription || _SUBSCRIPTION_DATE_HAS_NOT_ENDED) {
          setSubStatus("active");
          setSubID(res?.data?.response?.data?.[0]?.id);
          return;
        } else {
          setSubStatus(null);
          setSubID(null);
        }
      })
      .catch(() => {});
  }, [email, reload]);
  return {
    subscriptionStatus: subStatus,
    subscriptionID: subID,
    hasCancelledSubscription,
    subscriptionEndDate,
    navNotification,
  };
};
