import { Box, Flex } from "@chakra-ui/react";
import { Navigation } from "./Navigation";

function Container({
  withNav = true,
  children,
  sm_mt,
  mt,
  sm_px,
  px,
  props,
  props2,
}) {
  return (
    <Box {...props}>
      {withNav && (
        <Navigation
          top="0"
          px={["30px", "30px", "30px", "50px"]}
          position="fixed"
          background="white"
          zIndex="999"
        />
      )}
      <Flex
        flexDirection="column"
        px={[sm_px || "10px", "10px", "30px", px || "100px"]}
        className="pp"
        mt={[sm_mt || "150px", "150px", "150px", mt || "100px"]}
        {...props2}
      >
        {children}
      </Flex>
    </Box>
  );
}

export default Container;
