import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Suspense, lazy } from "react";
import Container from "./Container";
import { Flex } from "@chakra-ui/react";

const LoginRegister = lazy(() => import("../pages/LoginRegister"));
const Pricing = lazy(() => import("../pages/Pricing"));
const Editor = lazy(() => import("./editor/Editor"));
const About = lazy(() => import("../pages/About"));
const Contact = lazy(() => import("../pages/Contact"));
const FrontPage = lazy(() => import("../pages/FrontPage"));

export const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<p></p>}>
              <FrontPage />
            </Suspense>
          }
        />
        <Route
          path="/editor"
          element={
            <Suspense fallback={<p></p>}>
              <Editor />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<p></p>}>
              <About />
            </Suspense>
          }
        />
        <Route
          path="/login"
          element={
            <Suspense fallback={<p></p>}>
              <Flex flexDir="column" w="100%" justifyContent="center">
                <Container
                  sm_mt="0"
                  props2={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoginRegister
                    to={"SIGN_IN"}
                    props2={{
                      width: ["300px", "500px"],
                      maxW: "500px",
                      bg: "#fff",
                      color: "#000",
                      borderRadius: "10px",
                      py: "15px",
                      px: ["10px", "20px"],
                      mt: ["100px", "0"],
                      w: ["400px", "400px"],
                    }}
                    withNav={false}
                  />
                </Container>
              </Flex>
            </Suspense>
          }
        />
        <Route
          path="/register"
          element={
            <Suspense fallback={<p></p>}>
              <Flex flexDir="column" w="100%" justifyContent="center">
                <Container
                  sm_mt="0"
                  props2={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoginRegister
                    to={"REGISTER"}
                    props2={{
                      width: ["300px", "500px"],
                      maxW: "500px",
                      bg: "#fff",
                      color: "#000",
                      borderRadius: "10px",
                      py: "15px",
                      px: ["10px", "20px"],
                      mt: ["100px", "0"],
                      w: ["400px", "400px"],
                    }}
                    withNav={false}
                  />
                </Container>
              </Flex>
            </Suspense>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <Suspense fallback={<p></p>}>
                <Contact />
              </Suspense>
            </>
          }
        />
        <Route
          path="/pricing"
          element={
            <Suspense fallback={<p></p>}>
              <Pricing />
            </Suspense>
          }
        />

        <Route
          path="/*"
          element={
            <Suspense fallback={<p></p>}>
              <FrontPage />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
