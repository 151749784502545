export const SIZES = [
  { pixel: "15px", number: 1 },
  { pixel: "20px", number: 2 },
  { pixel: "30px", number: 5 },
  { pixel: "40px", number: 10 },
  { pixel: "50px", number: 20 },
  { pixel: "60px", number: 30 },
  { pixel: "70px", number: 40 },
  { pixel: "80px", number: 50 },
];

export const COMPANY_EMAIL = "no-reply@in-cert.uk";
export const BACKEND_BASE_URL = "https://in-cert-backend.onrender.com";
export const FREE_CERTIFICATE_DOWNLOAD_LIMIT = 15;
export const MAX_CSV_UPLOAD_LIMIT = 1000;
export const FONT_LIMIT = 27; // with system fonts making it 50
export const PAID_PRICE = 9;

// replace [ & ] with empty string
export const getRawText = (text) =>
  text?.replace(/\[/g, "")?.replace(/\]/g, "");

// get appropriate zoom width for destop & mobile screens
export const _getAppropriateZoomByWidth = (width) =>
  width < 2000 ? 0.3 : 0.18;

export const INPUT_TEXT_STYLE = {
  type: "text",
  placeholder: "Enter text",
  border: "none",
  bg: "#1a1c22",
  outline: "none",
  _focus: { border: "none" },
  color: "#fff",
  fontSize: "1em",
};
export const INPUT_SELECT_STYLE = {
  type: "text",
  border: "none",
  bg: "#1a1c22",
  outline: "none",
  fontSize: "1em",
  _focus: { border: "none" },
  color: "#fff",
};

export const CONTAINER_STYLE = {
  // h: "100vh",

  color: "#6c6c7a",
  fontWeight: "bold",
  fontSize: ".8em",
};

export let UPLOAD_CSV_CONTAINER_STYLE = {
  spacing: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

export let CONTAINER_INNER_STYLE = {
  maxW: "350px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#000",
};

export let INPUT_BOX_STYLE = {
  mt: "20px !important",
  w: "50px",
  h: "50px",
  bg: "#72e6ca",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export let IMPORT_STATUS_STYLE = {
  cursor: "pointer",
  fontSize: "1.5em",
  color: "#163631",
};

export let ARROW_RIGHT_STYLE = {
  cursor: "pointer",
  fontSize: "2em",
  color: "#163631",
};

export const _COMPUTER_FONTS = [
  "Arial",
  "Arial Black",
  "Bahnschrift *",
  "Comic Sans MS",
  "Impact",
  "Constantia",
  "Copperplate Gothic",
  "Consolas",
  "sans-serif",
  "Segoe UI",
  "Roboto",
  "Gill Sans",
  "Geneva",
  "Helvetica",
  "Helvetica Neue",
  "Lucida Sans",
  "monospace",
  "Noto",
  "Optima",
  "Courier New",
  "Droid Sans",
  "Times New Roman",
  "Verdana",
];

export let FOOTER_CONTAINER_STYLE = {
  direction: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#163631",
  color: "#fff",
  padding: "20px",
  spacing: "40px",
};

export let UPLOAD_IMAGE_CONTAINER = {
  spacing: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

export let UPLOAD_IMG_TEXT_CONTAINER = {
  maxW: "350px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#000",
};

export let UPLOAD_IMG_IMPORT_BOX = {
  mt: "20px !important",
  w: "50px",
  h: "50px",
  bg: "#72e6ca",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export let UPLOAD_IMG_ICON_STYLE = {
  cursor: "pointer",
  color: "#163631",
  fontSize: "1.5em",
};

export const NAVS = [
  { link: "/", title: "Home" },
  { link: "/contact", title: "Contact" },
  // { link: "/about", title: "About" },

  { link: "/pricing", title: "Pricing" },
  { link: "/login", title: "Login" },
  { link: "/register", title: "Register" },
];

export const MOBILE_NAVS = [
  { link: "/", title: "Home" },
  { link: "/contact", title: "Contact" },
  { link: "/pricing", title: "Pricing" },
  { link: "/login", title: "Login" },
  { link: "/register", title: "Register" },
  { link: "/editor", title: "Try for free" },
];

export let NAV_CONTAINER_STYLE = {
  justifyContent: "space-between",
  alignItems: "center",
  py: "10px",
  w: "100%",
};

export let NAV_LIST_CONTAINER_STYLE = {
  direction: "row",
  alignItems: "center",
  spacing: ["10px", "50px"],
  color: "#fff",
  fontSize: [".9em", "1em"],
};

export let NAV_CALL_TO_ACTION_STYLE = {
  bg: "#72e6ca",
  color: "#163631",
  _hover: { bg: "#72e6ca" },
  fontSize: [".8em", "1em"],
  size: "sm",
};

export const SIDE_BAR_INPUT_COLOR_STYLE = {
  type: "color",

  border: "none",
  width: "150px",
  maxW: "120px",
  cursor: "pointer",
  _focus: { border: "none" },
};

export let SIDE_BAR_EDITOR_INNER_CONTAINER = {
  direction: "column",
  width: "300px",
  maxWidth: "300px",
  spacing: "30px",
  position: "fixed",
  top: "0",
  right: "0",
  background: "#262730",
  height: "100%",
  padding: "20px",
};

export const DOWNLOAD_BTN_STYLE = {
  bg: "#72e6ca",
  _focus: { border: "none" },
  _hover: { bg: "#72e6ca" },

  fontWeight: "bold",
  color: "#163631",
};
